<aside class="left-sidebar" mode="side" *ngIf="acl">
    <div class="brand" tabindex="0" [ngClass]="{'disable-container-list': mfaMandatory}">
        <a class="nav-item" type="button" (click)="openHomePage()">
            <img class="small-logo" src="/assets/images/global/brand/brand-small.svg" alt="Countable"/>
        </a>
    </div>
    <mat-list role="list" class="left-nav" [ngClass]="{'disable-container-list': mfaMandatory}">
        <!--Client-->
        <ng-container *ngIf="acl.isClient">
            <mat-list-item role="list-item">
                <a class="active nav-item" type="button"
                   routerLink='/dashboard/clients/engagements' routerLinkActive="active"
                   (click)="viewPage('/dashboard/clients/engagements')"
                   [ngClass]="{'active': activePage == 'engagements', 'disable' : firm.isAccountLockedForBilling}" area-label="Engagements">
                    <span class="nav-icon">
                        <img src="/assets/images/ui-uplift-icons/global/nav-icons/engagements.svg" alt="Icon">
                    </span>
                    <span class="nav-label" (click)="stopNavigation($event)">Engagements</span>
                </a>
            </mat-list-item>
        </ng-container>
        <!-- partners and staff -->
        <ng-container *ngIf="!acl.isClient">
            <mat-list-item role="list-item">
                <a class="active nav-item" type="button"
                   routerLink='/dashboard/members/Dashboard' routerLinkActive="active"
                   [ngClass]="{'active': activePage == 'Dashboard', 'disable' : (disableAccess || firm.isAccountLockedForBilling) }"
                   (click)="viewPage('/dashboard/members/Dashboard')" area-label="Dashboard">
                    <span class="nav-icon">
                        <img src="/assets/images/ui-uplift-icons/global/nav-icons/dashboard.svg" alt="Icon">
                    </span>
                    <span class="nav-label" (click)="stopNavigation($event)">Dashboard</span>
                </a>
            </mat-list-item>
            <!-- only if the firm is onboarded -->
            <ng-container *ngIf="firm && firm.onboarded">
                <mat-list-item role="list-item">
                    <a class="nav-item" type="button" routerLinkActive="active"
                       routerLink='/dashboard/members/team' routerLinkActive="active"
                       [ngClass]="{'active': activePage == 'firmpartners', 'disable' : (disableAccess || firm.isAccountLockedForBilling)}"
                       (click)="viewPage('/dashboard/members/team')" area-label="Team">
                        <span class="nav-icon">
                            <img src="/assets/images/ui-uplift-icons/global/nav-icons/teams.svg" alt="Icon">
                        </span>
                        <span class="nav-label" (click)="stopNavigation($event)">Team</span>
                    </a>
                </mat-list-item>
                <mat-list-item role="list-item">
                    <a class="nav-item" type="button" routerLinkActive="active"
                       routerLink='/dashboard/members/clients' routerLinkActive="active"
                       [ngClass]="{'active': activePage == 'firmclients', 'disable' : (disableAccess || firm.isAccountLockedForBilling)}"
                       (click)="viewPage('/dashboard/members/clients')" area-label="Clients">
                        <span class="nav-icon">
                            <img src="/assets/images/ui-uplift-icons/global/nav-icons/clients.svg" alt="Icon">
                        </span>
                        <span class="nav-label" (click)="stopNavigation($event)">Clients</span>
                    </a>
                </mat-list-item>
                <mat-list-item role="list-item">
                    <a class="nav-item" type="button" area-label="Engagements"
                       routerLink='/dashboard/engagement/engagement-list' routerLinkActive="active"
                       [ngClass]="{'active': activePage == 'engagement', 'disable' : (disableAccess || firm.isAccountLockedForBilling)}"
                       (click)="viewPage('/dashboard/engagement/engagement-list')">
                        <span class="nav-icon">
                            <img src="/assets/images/ui-uplift-icons/global/nav-icons/engagements.svg" alt="Icon">
                        </span>
                        <span class="nav-label" (click)="stopNavigation($event)">Engagements</span>
                    </a>
                </mat-list-item>
                <mat-list-item role="list-item" *ngIf="acl?.isSuperAdmin || userDetail?.istemplateenabled">
                    <a class="nav-item" type="button"
                       routerLink='/dashboard/firm/templates/engagement-templates' routerLinkActive="active"
                       (click)="viewPage('/dashboard/firm/templates/engagement-templates')"
                       [ngClass]="{'active': activePage == 'templates', 'disable' : (disableAccess || firm.isAccountLockedForBilling)}"
                       area-label="Templates">
                        <span class="nav-icon">
                            <img src="/assets/images/ui-uplift-icons/global/nav-icons/templates.svg" alt="Icon">
                        </span>
                        <span class="nav-label" (click)="stopNavigation($event)">Templates</span>
                    </a>
                </mat-list-item>
                <mat-list-item role="list-item"
                               *ngIf="isTrackerOn ? false : (acl.hasEngView && acl.hasTimeSummaryView)">
                    <a class="nav-item" type="button"
                       routerLink='/dashboard/timetracking/time-summary' routerLinkActive="active"
                       (click)="viewPage('/dashboard/timetracking/time-summary')" aria-label="Time Summary"
                       [ngClass]="{'active': activePage == 'timetracking'}">
                        <span class="nav-icon">
                            <img src="/assets/images/ui-uplift-icons/global/nav-icons/time-summary.svg" alt="Icon">
                        </span>
                        <span class="nav-label" (click)="stopNavigation($event)">Time Summary</span>
                    </a>
                </mat-list-item>
            </ng-container>
        </ng-container>
    </mat-list>
    <div class="user-detail-main d-none d-lg-block">
        <app-side-menu-bottom></app-side-menu-bottom>
    </div>
</aside>
