import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, signal, TemplateRef, ViewChild, ViewEncapsulation, WritableSignal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { BillingModel } from 'src/app/model/billing/billing.model';
import { FirmModel } from 'src/app/model/firm/firm.model';
import { getenvUrl } from 'src/app/services/app-config.service';
import { BillingService } from 'src/app/services/billing.service';
import { FirmService } from 'src/app/services/firm.service';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../../components/shared/shared.service';
import { ApiService } from '../../../services/api.service';
import { ClientService } from '../../../services/client.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-import-csv',
  templateUrl: './import-csv-component.html',
  styleUrls: ['./import-csv-component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],
  standalone: false
})
export class ImportCsvComponent implements OnInit, OnDestroy {
  @ViewChild('howtoEdit') howtoEdit: TemplateRef<any>;
  @ViewChild('importCsvDialog') importCsvDialog: TemplateRef<any>;
  role: any;
  records: any = [];
  partnersList: any = [];
  clientsList: any = [];
  staffList: any = [];
  processType: any = 'import';
  @ViewChild('csvReader') csvReader: any;
  @ViewChild('csvReupload') csvReupload: any;
  uploadSuccess: any = false;
  addPartnersColumns: string[] = ['id', 'practiceUnit', 'name', 'email', 'license', 'OfficeLocation', 'businessNumber', 'cell', 'emailValidationStatus', 'actions'];
  addClientColumns: string[] = ['id', 'legalentityname', 'typeofentity', 'incorporationdate', 'contactpersonname', 'email', 'businessPhoneNo', 'cellPhoneNo', 'groupName', 'jurisdiction', 'engagementPartnerName', 'engagementPartnerNameEmail', 'emailValidationStatus', 'actions'];
  partnersStaffColumns: string[] = ['id', 'firstName', 'practiceUnit', 'designationName', 'emailAddress', 'license', 'officeLocation', 'businessNo', 'phoneNo', 'emailValidationStatus', 'actions'];
  loggedInUserData: any;
  useraccountId: any;
  practiceUnitsList: any = [];
  designationDetails: any = [];
  jurisdictionDetails: any = [];
  typeOfEntityDetails: any = [];
  subtypeOfEntityDetails: any = [];
  displayRole: any;
  templateUrl: any;
  bucketName: any;
  onlyNumberPattern = /^[0-9]*$/;
  alphanumericPattern = /^[a-z0-9]+$/i;
  //alphanumericDecimalPattern = /^[a-z0-9.]+$/i;
  alphanumericDecimalPattern = /^[a-z0-9. ]+$/i;
  statusCheck = {0: 'Ready to Import', 1: 'Email already Exists', 2: 'Duplicate', 3: 'Invalid Email'};
  isIdsMatched: boolean = false;
  isEditMode: boolean = false;
  isValidFile: boolean = false;
  getAllIdsList: any;
  idRole: any;
  isPartnerStaffSheet: boolean = false;
  isClientSheet: boolean = false;
  currentEmail: any;
  currentId: any;
  downloadPDFFile: any;
  envURL: any;
  Emptyentityname: any;

  ErrorStatus: any;
  firstNamestatus: any;
  LastnameStatus: any;
  Entitystatus: any;
  EntityNamestatus: any;
  billing: BillingModel;
  firm: FirmModel;
  targetTemplateName: WritableSignal<string> = signal<string>('');
  private subscriptions: Subscription = new Subscription();

  constructor(private route: ActivatedRoute, private router: Router, private toastr: ToastrService, private spinner: NgxSpinnerService,
              private apiService: ApiService, private datePipe: DatePipe, private dialog: MatDialog,
              private sharedService: SharedService, private firmService: FirmService, private billingService: BillingService,
              private userService: UserService, private clientService: ClientService) {
    this.route.queryParams.subscribe(params => {
      this.role = params['role'];
      this.displayRole = this.role;

      if (this.role === 'partner') {
        this.idRole = 'partners';
      } else if (this.role == 'client' || this.role == 'partnerclient') {
        this.idRole = 'clients';
      } else if (this.role == 'staff' || this.role == 'partnerstaff') {
        this.idRole = 'staffs';
      }

    });
    this.subscriptions.add(
      this.firmService.subject.subscribe(firm => {
        if (firm) {
          this.firm = firm;
        }
      })
    );

    this.subscriptions.add(
      this.billingService.subject.subscribe(billing => {
        if (billing) {
          this.billing = billing;
        }
      })
    );
  }

  ngOnInit() {
    this.envURL = getenvUrl();
    this.loggedInUserData = JSON.parse((localStorage.getItem('userDetails')));
    if (this.loggedInUserData && this.loggedInUserData.useracctid) {
      this.useraccountId = this.loggedInUserData.useracctid;
    }
    this.bucketName = this.loggedInUserData.s3BucketName;
    if (!this.role) {
      this.router.navigate(['/']);
    }

    this.getDeisgnationDetails();
    this.getJurisdiction();
    this.getTypeOfEntity();
    this.gettypeofsubentity();

    if (this.role === 'partner') {
      this.displayRole = 'Partners';
      this.sharedService.getS3url(this.bucketName, 'FirmPartnerImport_Template.csv').subscribe(url => {
        this.templateUrl = url;
        this.targetTemplateName.set('FirmPartnerImport_Template.csv');
      });
    } else if (this.role === 'client' || this.role === 'partnerclient') {
      this.displayRole = 'Clients';
      if (this.loggedInUserData.origin_country_id == 226) {
        this.sharedService.getS3url(this.bucketName, 'csvtemplate/v1/AccountClientImport_USA.csv').subscribe(url => {
          this.templateUrl = url;
          this.targetTemplateName.set('AccountClientImport_USA.csv');
        });
        this.addClientColumns = ['id', 'legalentityname', 'typeofentity', 'subenttype', 'incorporationdate', 'contactpersonname', 'email', 'businessPhoneNo', 'cellPhoneNo', 'groupName', 'jurisdiction', 'engagementPartnerName', 'engagementPartnerNameEmail', 'emailValidationStatus', 'actions'];
      } else {
        this.sharedService.getS3url(this.bucketName, 'csvtemplate/v1/AccountClientImport.csv').subscribe(url => {
          this.templateUrl = url;
          this.targetTemplateName.set('AccountClientImport.csv');
        });
        this.addClientColumns = ['id', 'legalentityname', 'typeofentity', 'incorporationdate', 'contactpersonname', 'email', 'businessPhoneNo', 'cellPhoneNo', 'groupName', 'jurisdiction', 'engagementPartnerName', 'engagementPartnerNameEmail', 'emailValidationStatus', 'actions'];
      }
    } else if (this.role === 'staff' || this.role === 'partnerstaff') {
      this.displayRole = 'Staff';
      this.sharedService.getS3url(this.bucketName, 'FirmStaffImport_Template.csv').subscribe(url => {
        this.templateUrl = url;
        this.targetTemplateName.set('FirmStaffImport_Template.csv');
      });
    } else {
      this.router.navigate(['/']);
    }
  }

  onBackClick() {
    if (this.role === 'client' || this.role === 'partnerclient') {
      if (this.role === 'client') {
        this.router.navigate(['/dashboard/firm/clients']);
      } else {
        // this.router.navigate(['/dashboard/partner/partnerclients']);
        this.router.navigate(['dashboard/members/clients']);

      }
    } else if (this.role === 'staff' || this.role === 'partnerstaff') {
      if (this.role === 'staff') {
        this.router.navigate(['/dashboard/firm/firmstaff']);
      } else {
        this.router.navigate(['/dashboard/partner/partnerstaff']);
      }
    } else if (this.role === 'partner') {
      this.router.navigate(['/dashboard/firm/partners']);
    }
  }

  downloadPDF() {
    if (this.role === 'partner' || this.role === 'staff') {
      this.apiService.getFiles('Import Templates/pdf/Firm-Team.pdf').subscribe(res => {
        this.downloadPDFFile = res;
        if (this.downloadPDFFile) {
          window.open(this.downloadPDFFile, '_blank');
        }
      }, error => {});
    } else if (this.role === 'client') {
      this.apiService.getFiles('Import Templates/pdf/Firm-Team.pdf').subscribe(res => {
        this.downloadPDFFile = res;
        if (this.downloadPDFFile) {
          window.open(this.downloadPDFFile, '_blank');
        }
      }, error => {});
    } else if (this.role === 'partnerstaff') {
      this.apiService.getFiles('Import Templates/pdf/Firm-Team.pdf').subscribe(res => {
        this.downloadPDFFile = res;
        if (this.downloadPDFFile) {
          window.open(this.downloadPDFFile, '_blank');
        }
      }, error => {});
    } else if (this.role === 'partnerclient') {
      this.apiService.getFiles('Import Templates/pdf/Partner_Client.pdf').subscribe(res => {
        this.downloadPDFFile = res;
        if (this.downloadPDFFile) {
          window.open(this.downloadPDFFile, '_blank');
        }
      }, error => {});
    }
  }

  uploadListener($event: any, type): void {
    let files: any = [];
    let invalidLicenseList: any = [];
    let businessArray = [], cellArray = [];
    let text = [];
    if (type == 'manual') {
      files = $event.srcElement.files;
    } else {
      files = $event; // for drag and drop file upload
    }

    if (files && files.length > 1) {
      this.toastr.error('Upload one file at a time');
    } else {
      if (this.isValidCSVFile(files[0])) {
        let input: any = [];
        if (type == 'manual') {
          input = $event.target.files[0];
        } else {
          input = files[0];
        }

        let reader = new FileReader();
        reader.readAsText(input);

        reader.onload = () => {
          let csvData = reader.result;
          let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
          let headersRow = this.getHeaderArray(csvRecordsArray);

          if (this.isCsvColumnLengthValid(headersRow.length)) {
            this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);

            this.partnersList = [];
            this.clientsList = [];
            this.staffList = [];
            //Duplicate License Entries Checking
            if (!(this.role == 'client' || this.role == 'partnerclient')) {
              var licenseArr = this.records.map(function (item) { return item.licenseNo; });
              var isDuplicateLicense = licenseArr.some(function (item, idx) {
                return licenseArr.indexOf(item) != idx;
              });

            }
            //
            this.records.forEach(obj => {
              //Checking for Alphanumeric Id
              if (obj['isAlphanumericId']) {
                delete obj['isAlphanumericId'];
              }
              if (obj['isIdValidLength']) {
                delete obj['isIdValidLength'];
              }
              if (obj.id) {
                if (obj.id.length > 100) {
                  obj['isIdValidLength'] = 1;
                } else {
                  obj['isIdValidLength'] = 0;
                }

              }
              // if (!obj.id.match(this.alphanumericPattern)) {
              //   obj['isAlphanumericId'] = 1;
              // }
              // else {
              //   obj['isAlphanumericId'] = 0;
              // }
              //
              if (!(this.role == 'client' || this.role == 'partnerclient')) { // Partner and Staff
                if ((this.role == 'partner') && (obj.role && obj.role.toLowerCase() == 'partner')) {
                  obj.isInvite = true;
                  this.partnersList.push(obj);
                  if ((!obj.licenseNo.match(this.onlyNumberPattern) || obj.licenseNo === '' || obj.licenseNo.length > 11)) {
                    invalidLicenseList.push(obj.licenseNo);
                  }

                } else if ((this.role == 'staff' || this.role == 'partnerstaff') && (obj.role && obj.role.toLowerCase() == 'team member') ||
                  (this.role == 'staff' || this.role == 'partnerstaff') && (obj.role.toLowerCase() == 'staff') ||
                  (this.role == 'staff' || this.role == 'partnerstaff') && (obj.role.toLowerCase() == 'teammember')) {
                  obj.isInvite = true;
                  this.staffList.push(obj);
                  if ((!obj.licenseNo.match(this.onlyNumberPattern) || obj.licenseNo === '' || obj.licenseNo.length > 11)) {
                    invalidLicenseList.push(obj.licenseNo);
                  }
                }
              } else if (this.role == 'client' || this.role == 'partnerclient') { // Client

                /* if (obj.role && obj.role.toLowerCase() == 'client') { // Role based in CSV
                 this.clientsList.push(obj);
                 } */

                if (!obj.id.match(this.alphanumericDecimalPattern)) {
                  obj['isAlphanumericId'] = 1;
                } else {
                  obj['isAlphanumericId'] = 0;
                }

                if ((this.role == 'client' || this.role == 'partnerclient') && (obj.role && obj.role.toLowerCase() == 'team member') ||
                  (this.role == 'client' || this.role == 'partnerclient') && (obj.role.toLowerCase() == 'staff') ||
                  (this.role == 'client' || this.role == 'partnerclient') && (obj.role.toLowerCase() == 'teammember') ||
                  (this.role == 'client' || this.role == 'partnerclient') && (obj.role.toLowerCase() == 'partner')) { // Role based in CSV
                  this.clientsList = [];
                } else if (obj.businessPhone && (obj.businessPhone.length > 10 || obj.businessPhone.length < 10)) {
                  businessArray.push(obj.businessPhone);
                } else if (obj.cellPhone && (obj.cellPhone.length > 10 || obj.cellPhone.length < 10)) {
                  cellArray.push(obj.cellPhone);
                } else {
                  this.clientsList.push(obj);
                }
                //this.clientsList.push(obj); // Without role based in CSV
              }
            }); // Loop Ended

            //
            if (invalidLicenseList.length === 0) { // Valid Licence
              if (!isDuplicateLicense) {
                if (this.role === 'partner') {
                  if (this.partnersList.length === 0) {
                    this.uploadSuccess = false;
                    this.toastr.error('Please upload partner list');
                  } else {
                    this.onPartnerEmailCheck();
                    this.onUniqueIdCheck();
                    this.onPhoneNumberCheck();
                    this.uploadSuccess = true;
                  }
                } else if (this.role === 'client' || this.role === 'partnerclient') {
                  if (businessArray.length > 0 && cellArray.length > 0) {
                    // this.uploadSuccess = false;
                    this.toastr.error('Invalid Business | Cell Phone: Allowed 10 digits');
                  } else if (businessArray.length > 0) {
                    // this.uploadSuccess = false;
                    this.toastr.error('Invalid Business Phone: Allowed 10 digits');
                  } else if (cellArray.length > 0) {
                    // this.uploadSuccess = false;
                    this.toastr.error('Invalid Cell Phone: Allowed 10 digits');
                  } else if (this.clientsList.length === 0) {
                    this.uploadSuccess = false;
                    this.toastr.error('Please upload client list');
                  }
                    // else if(this.clientsList.typeOfEntityName == ""){
                    //   this.uploadSuccess = false;
                  // }
                  else if (this.clientsList.length > 0) {
                    this.onClientEmailCheck();
                    this.onClientEmailEntityCheck();
                    this.onUniqueIdCheck();
                    this.onPhoneNumberCheck();
                    this.MandatoryValidation();
                    this.uploadSuccess = true;
                  }
                } else if (this.role === 'staff' || this.role === 'partnerstaff') {
                  if (this.staffList.length === 0) {
                    this.uploadSuccess = false;
                    this.toastr.error('Please upload staff list');
                  } else {
                    this.onStaffEmailCheck();
                    this.onUniqueIdCheck();
                    this.onPhoneNumberCheck();
                    this.uploadSuccess = true;
                  }
                }
              } else {
                if (this.role === 'partner') {
                  if (this.partnersList.length === 0) {
                    this.uploadSuccess = false;
                    this.toastr.error('Please upload partner list');
                  } else {
                    this.openLicenceDialog();
                  }
                } else if (this.role === 'staff' || this.role === 'partnerstaff') {
                  if (this.staffList.length === 0) {
                    this.uploadSuccess = false;
                    this.toastr.error('Please upload staff list');
                  } else {
                    this.openLicenceDialog();
                  }
                } else if (this.role === 'client' || this.role === 'partnerclient') {
                  if (this.clientsList.length === 0) {
                    this.uploadSuccess = false;
                    this.toastr.error('Please upload client list');
                  } else {
                    console.log('Client Sheet Error');
                  }
                }
              }

            } else { // In Valid Licence
              /* this.toastr.error('Only Numeric and Max 11 Numeric!'); */
              this.openLicenceDialog();
            }
          } else {
            // Column mismatched
            this.uploadSuccess = false;

            this.partnersList = [];
            this.clientsList = [];
            this.staffList = [];
            this.toastr.error('All required columns are not present in the file. Please compare with given template and re-import.');
          }
        };
        reader.onerror = function () {
          console.log('error is occurred while reading file!');
        };
      } else {
        this.toastr.error('Please import valid file type (CSV)');
        this.fileReset();
      }
    }
    invalidLicenseList = [];
  }

  MandatoryValidation() {
    this.clientsList.forEach(element => {
      const ContactName = element.contactPersonName.trim().split(' ', 2);
      element.firstName = ContactName[0];
      element.lastName = ContactName[1];
      if (element.firstName == '' || element.firstName == undefined) {
        element['InvalidFirstName'] = 1;
      } else {
        element['InvalidFirstName'] = 0;
      }
      if (element.lastName == '' || element.lastName == undefined) {
        element['InvalidLastName'] = 1;
      } else {
        element['InvalidLastName'] = 0;
      }
      if (element.typeOfEntityName == '' || element.typeOfEntityName.toLowerCase() != 'corporation') {
        element['InvalidEntityType'] = 1;
      } else {
        element['InvalidEntityType'] = 0;
      }
      if (element.legalEntityName == '') {
        element['InvalidEntityName'] = 1;
      } else {
        element['InvalidEntityName'] = 0;
      }
    });
  }

  onPhoneNumberCheck() {
    if (this.role === 'partner') {
      this.partnersList.forEach(element => {
        if (element.partnerBusinessPhone) {
          let num = element.partnerBusinessPhone.split('-').join('').split('(').join('').split(')').join('');
          const numberPattern = num.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
          element['partnerBusinessPhone'] = numberPattern;
        }

        if (element.PartnerCellNo) {
          let num = element.PartnerCellNo.split('-').join('').split('(').join('').split(')').join('');
          const numberPattern = num.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
          element['PartnerCellNo'] = numberPattern;
        }
      });
    } else if (this.role === 'client' || this.role === 'partnerclient') {
      this.clientsList.forEach(element => {
        if (element.businessPhone) {
          let num = element.businessPhone.split('-').join('').split('(').join('').split(')').join('');
          const numberPattern = num.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
          element['businessPhone'] = numberPattern;
        }

        if (element.cellPhone) {
          let num = element.cellPhone.split('-').join('').split('(').join('').split(')').join('');
          const numberPattern = num.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
          element['cellPhone'] = numberPattern;
        }
      });
    } else if (this.role === 'staff' || this.role === 'partnerstaff') {
      this.staffList.forEach(element => {
        if (element.partnerBusinessPhone) {
          let num = element.partnerBusinessPhone.split('-').join('').split('(').join('').split(')').join('');
          const numberPattern = num.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
          element['partnerBusinessPhone'] = numberPattern;
        }

        if (element.PartnerCellNo) {
          let num = element.PartnerCellNo.split('-').join('').split('(').join('').split(')').join('');
          const numberPattern = num.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
          element['PartnerCellNo'] = numberPattern;
        }
      });
    }
  }

  onUniqueIdCheck() {
    let roleId = '';
    let tempList: any = [];

    if (this.role == 'partner') {
      roleId = 'partners';
      this.partnersList.forEach(element => {
        if (element['duplicateIDStatus']) {
          delete element['duplicateIDStatus'];
        }
        if (element['isAlphanumericId']) {
          delete element['isAlphanumericId'];
        }
        if (element['isIdValidLength']) {
          delete element['isIdValidLength'];
        }
      });

      tempList = this.partnersList;
      tempList.forEach(tempElement => {
        let count = 0;
        this.partnersList.forEach(element => {
          if (element.id) {
            if (tempElement.id === element.id) {
              if (count < 1) {
                count = count + 1;
              } else {
                count = count + 1;
                element['duplicateIDStatus'] = 1;
              }
            }

            if (!element.id.match(this.alphanumericPattern)) {
              element['isAlphanumericId'] = 1;
            } else {
              element['isAlphanumericId'] = 0;
            }

            if (element.id.length > 100) {
              element['isIdValidLength'] = 1;
            } else {
              element['isIdValidLength'] = 0;
            }
          }
        });
      });
    } else if (this.role == 'client' || this.role == 'partnerclient') {
      roleId = 'clients';
      this.clientsList.forEach(element => {
        if (element['duplicateIDStatus']) {
          delete element['duplicateIDStatus'];
        }
        if (element['isAlphanumericId']) {
          delete element['isAlphanumericId'];
        }
        if (element['isIdValidLength']) {
          delete element['isIdValidLength'];
        }
      });

      tempList = this.clientsList;
      tempList.forEach(tempElement => {
        let count = 0;
        this.clientsList.forEach(element => {
          if (element.id) {
            if (tempElement.id === element.id) {
              if (count < 1) {
                count = count + 1;
              } else {
                count = count + 1;
                element['duplicateIDStatus'] = 1;
              }
            }
            if (!element.id.match(this.alphanumericDecimalPattern)) {
              element['isAlphanumericId'] = 1;
            } else {
              element['isAlphanumericId'] = 0;
            }

            if (element.id.length > 100) {
              element['isIdValidLength'] = 1;
            } else {
              element['isIdValidLength'] = 0;
            }
          }
        });
      });
    } else if (this.role == 'staff' || this.role == 'partnerstaff') {
      roleId = 'staffs';
      this.staffList.forEach(element => {
        if (element['duplicateIDStatus']) {
          delete element['duplicateIDStatus'];
        }
        if (element['isAlphanumericId']) {
          delete element['isAlphanumericId'];
        }
        if (element['isIdValidLength']) {
          delete element['isIdValidLength'];
        }
      });

      tempList = this.staffList;
      tempList.forEach(tempElement => {
        let count = 0;
        this.staffList.forEach(element => {
          if (element.id) {
            if (tempElement.id === element.id) {
              if (count < 1) {
                count = count + 1;
              } else {
                count = count + 1;
                element['duplicateIDStatus'] = 1;
              }
            }
            if (!element.id.match(this.alphanumericPattern)) {
              element['isAlphanumericId'] = 1;
            } else {
              element['isAlphanumericId'] = 0;
            }
            if (element.id.length > 100) {
              element['isIdValidLength'] = 1;
            } else {
              element['isIdValidLength'] = 0;
            }
          }
        });
      });
    }

    this.userService.getAllUserTitles(roleId).subscribe({
      next: res => {
        if (res) {
          let result: any = [];
          result = JSON.parse(res);
          result.forEach(tempElement => {
            if (this.role === 'partner') {
              //Partner List from server
              this.partnersList.forEach(element => {
                if (tempElement.id === element.id) {
                  element['duplicateIDStatus'] = 2;
                }
              });
            } else if (this.role == 'client' || this.role == 'partnerclient') {
              //Client List from server
              this.clientsList.forEach(element => {
                if (tempElement.id === element.id && element.id != '') {
                  element['duplicateIDStatus'] = 2;
                }
              });
            } else if (this.role == 'staff' || this.role == 'partnerstaff') {
              //Staff List from server
              this.staffList.forEach(element => {
                if (tempElement.id === element.id) {
                  element['duplicateIDStatus'] = 2;
                }
              });
            }
          });
        }
      }, error: e => {
        this.spinner.hide();
      }
    });
  }

  onPartnerEmailCheck() {
    let index = 0;
    this.partnersList.forEach(element => {
      if (element['emailValidationStatus']) {
        delete element['emailValidationStatus'];
      }
    });
    let tempList = this.partnersList;
    tempList.forEach(tempElement => {
      let count = 0;
      this.partnersList.forEach(element => {
        if (!element.partnerEmail.match(environment.EMAIL_REG_EXP) || element.partnerEmail === '') {
          element['emailValidationStatus'] = 3;
        }
        if (tempElement.partnerEmail === element.partnerEmail) { // Duplicate Items
          if (count < 1) {
            count = count + 1;
          } else {
            count = count + 1;
            element['emailValidationStatus'] = 2;
          }
        }
      });
    });
    // Email ID Check
    this.partnersList.forEach(element => {
      this.firmService.validateEmail(element.partnerEmail).subscribe(res => {
        if (element['emailValidationStatus'] === 2 || element['emailValidationStatus'] === 3) {
          element['emailValidationStatus'] = element['emailValidationStatus'];
        } else {
          element['emailValidationStatus'] = res.status === 200 ? 0 : 1;
        }
        element['isEditMode'] = false;
        element['index'] = index;
        index = index + 1;
      });
    });
    this.spinner.hide().then();
  }

  checkCharLength(text: string) {
    if (this.role === 'partner') { // Partner
      if (text.length === 0 || text.length < 1) {
        this.partnersList.map(e => {
          e['isEmptyField'] = true;
        });
      } else {
        this.partnersList.map(e => {
          e['isEmptyField'] = false;
        });
      }
    } // End
    else if (this.role == 'client' || this.role == 'partnerclient') {// Client
      if (text.length === 0 || text.length < 1) {
        this.clientsList.map(e => {
          e['isEmptyField'] = true;
        });
      } else {
        this.clientsList.map(e => {
          e['isEmptyField'] = false;
        });
      }
    } // End
    else if (this.role == 'staff' || this.role == 'partnerstaff') { // Staff
      if (text.length === 0 || text.length < 1) {
        this.staffList.map(e => {
          e['isEmptyField'] = true;
        });
      } else {
        this.staffList.map(e => {
          e['isEmptyField'] = false;
        });
      }
    }
  }

  onlyAlphNumericKeys(e) { //without space and underscre
    let specialKeys = [];
    specialKeys.push(8);  //Backspace
    specialKeys.push(9);  //Tab
    specialKeys.push(46); //Delete
    specialKeys.push(36); //Home
    specialKeys.push(35); //End
    specialKeys.push(37); //Left
    specialKeys.push(39); //Right
    let keyCode = e.keyCode == 0 ? e.charCode : e.keyCode;
    let ret = ((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 64 && keyCode <= 90) ||
      (keyCode >= 97 && keyCode <= 122) ||
      (keyCode == 35) || (specialKeys.indexOf(e.keyCode) != -1 && e.charCode != e.keyCode));
    return ret;
  }

  onStaffEmailCheck() {
    let index = 0;

    this.staffList.forEach(element => {
      if (element['emailValidationStatus']) {
        delete element['emailValidationStatus'];
      }
    });

    let tempList = this.staffList;

    tempList.forEach(tempElement => {
      let count = 0;
      this.staffList.forEach(element => {
        if (!element.partnerEmail.match(environment.EMAIL_REG_EXP) || element.partnerEmail === '') {
          element['emailValidationStatus'] = 3;
        }
        if (tempElement.partnerEmail === element.partnerEmail) {
          if (count < 1) {
            count = count + 1;
          } else {
            count = count + 1;
            element['emailValidationStatus'] = 2;
          }
        }
      });
    });

    this.staffList.forEach(element => {
      this.spinner.show().then();
      this.firmService.validateEmail(element.partnerEmail).subscribe(res => {
        if (element['emailValidationStatus'] === 2 || element['emailValidationStatus'] === 3) {
          element['emailValidationStatus'] = element['emailValidationStatus'];
        } else {
          element['emailValidationStatus'] = (res.status === 200) ? 0 : 1;
        }
        element['isEditMode'] = false;
        element['index'] = index;
        index = index + 1;
      }).add(() => this.spinner.hide());
    });
  }

  onClientEmailCheck() {
    let index = 0;
    this.clientsList.forEach(element => {
      if (element['emailValidationStatus']) {
        delete element['emailValidationStatus'];
      }
    });

    let tempList = this.clientsList;

    tempList.forEach(tempElement => {
      this.clientsList.forEach(element => {
        if (!element.clientEmail.match(environment.EMAIL_REG_EXP) || element.clientEmail === '') {
          element['emailValidationStatus'] = 3;
        }
      });
    });
    this.clientsList.forEach(element => {
      this.spinner.show().then();
      this.firmService.validateEmail(element.clientEmail, 4).subscribe(res => {
        if (element['emailValidationStatus'] === 2 || element['emailValidationStatus'] === 3) {
          element['emailValidationStatus'] = element['emailValidationStatus'];
        } else {
          element['emailValidationStatus'] = (res.status === 200) ? 0 : 1;
        }
        element['isEditMode'] = false;
        element['index'] = index;
        index = index + 1;
      }).add(() => this.spinner.hide());
    });
  }

  onClientEmailEntityCheck() {
    let index = 0;

    this.clientsList.forEach(element => {
      if (element['duplicateEmailEntityStatus']) {
        delete element['duplicateEmailEntityStatus'];
      }
    });

    let tempList = this.clientsList;
    tempList.forEach(tempElement => {
      let count = 0;
      this.clientsList.forEach(element => {
        if (element.legalEntityName === '') {
          element['duplicateEmailEntityStatus'] = 3;
        }
        if (tempElement.legalEntityName === element.legalEntityName && tempElement.clientEmail === element.clientEmail) {
          if (count < 1) {
            count = count + 1;
          } else {
            count = count + 1;
            element['duplicateEmailEntityStatus'] = 2;
          }
        }
      });
    });

    this.clientsList.forEach(element => {
      this.spinner.show();
      this.clientService.validateClientEntityName(element.legalEntityName, element.clientEmail).subscribe({
        next: res => {
          this.spinner.hide();
          let result: any = [];
          result = JSON.parse(res);
          if (element['duplicateEmailEntityStatus'] === 2 || element['duplicateEmailEntityStatus'] === 3) {
            element['duplicateEmailEntityStatus'] = element['duplicateEmailEntityStatus'];
          } else {
            element['duplicateEmailEntityStatus'] = result[0].validatestatus;
          }
          element['isEditMode'] = false;
          element['index'] = index;
          index = index + 1;
        }, error: e => {
          this.spinner.hide();
        }
      });
    });
  }

  getEditRowData(rowData) {
    //this.currentEmail = rowData.clientEmail;
    if (this.role === 'client' || this.role === 'partnerclient') {
      this.currentEmail = rowData.clientEmail;
      this.currentId = rowData.id;
    } else if (this.role === 'staff' || this.role === 'partnerstaff') {
      this.currentEmail = rowData.partnerEmail;
      this.currentId = rowData.id;
    } else if (this.role === 'partner') {
      this.currentEmail = rowData.partnerEmail;
      this.currentId = rowData.id;
    }
  }

  onEditSave(element) {
    if (this.role === 'client' || this.role === 'partnerclient') {
      this.toastr.success('Client record updated successfully');
      this.onClientEmailCheck();
      this.onClientEmailEntityCheck();
      this.onUniqueIdCheck();
      this.MandatoryValidation();
    } else if (this.role === 'staff' || this.role === 'partnerstaff') {
      this.toastr.success('Staff updated successfully');
      this.onStaffEmailCheck();
      this.onUniqueIdCheck();
    } else if (this.role === 'partner') {
      this.toastr.success('Partner updated successfully');
      this.isIdsMatched = false;
      this.onPartnerEmailCheck();
      this.onUniqueIdCheck();
    }
  }

  onEditDelete(element) {
    if (this.role === 'client' || this.role === 'partnerclient') {
      this.clientsList = this.clientsList.filter(clientId => clientId.index !== element.index);
      this.toastr.success('Client record deleted successfully');
      this.onClientEmailCheck();
      this.onClientEmailEntityCheck();
      this.onUniqueIdCheck();
      if (this.clientsList.length === 0) {
        this.uploadSuccess = false;
      }
    } else if (this.role === 'staff' || this.role === 'partnerstaff') {
      this.staffList = this.staffList.filter(staffId => staffId.index !== element.index);
      this.toastr.success('Staff deleted successfully');
      this.onStaffEmailCheck();
      this.onUniqueIdCheck();
      if (this.staffList.length === 0) {
        this.uploadSuccess = false;
      }
    } else if (this.role === 'partner') {
      this.partnersList = this.partnersList.filter(partnerId => partnerId.index !== element.index);
      this.toastr.success('Partner deleted successfully');
      this.onPartnerEmailCheck();
      this.onUniqueIdCheck();
      if (this.partnersList.length === 0) {
        this.uploadSuccess = false;
      }
    }
  }

  onValidationClick() {
    if (this.role === 'client' || this.role === 'partnerclient') {
      for (const item of this.clientsList) {
        if (item.emailValidationStatus === 1 || item.emailValidationStatus === 2 || item.isAlphanumericId === 1 || item.isIdValidLength
          || item.emailValidationStatus === 3 || item.duplicateIDStatus || item.isEditMode === true || item.duplicateEmailEntityStatus === 1 || item.duplicateEmailEntityStatus === 2 || item.duplicateEmailEntityStatus === 3 || item.InvalidEntityType == 1 || item.InvalidFirstName == 1 || item.InvalidLastName == 1) {
          return true;
          break;
        }
      }
    } else if (this.role === 'staff' || this.role === 'partnerstaff') {
      for (const item of this.staffList) {
        if (item.emailValidationStatus === 1 || item.emailValidationStatus === 2 || item.isAlphanumericId === 1 || item.isIdValidLength
          || item.emailValidationStatus === 3 || item.duplicateIDStatus || item.isEditMode === true) {
          return true;
          break;
        }
      }
    } else if (this.role === 'partner') {
      for (const item of this.partnersList) {
        if (item.emailValidationStatus === 1 || item.emailValidationStatus === 2 || item.isAlphanumericId === 1 || item.isIdValidLength
          || item.emailValidationStatus === 3 || item.duplicateIDStatus || item.isEditMode === true) {
          return true;
          break;
        }

      }
    }
  }

  public onlyLowercase(event, element, role) {
    if (role === 'partner' || role === 'staff') {
      element.partnerEmail = event.target.value.toLowerCase();
    } else if (role === 'client') {
      element.clientEmail = event.target.value.toLowerCase();
    }
  }

  // Checks if the csv file has the correct number of columns
  isCsvColumnLengthValid(headerLength): boolean {
    if (this.role == 'staff' || this.role == 'partnerstaff' || this.role === 'partner') {
      return headerLength == 19;
    } else if (this.role == 'client' || this.role == 'partnerclient') {
      if (this.loggedInUserData.origin_country_id == 38) {
        return headerLength == 18;
      } else if (this.loggedInUserData.origin_country_id == 226) {
        return headerLength == 19;
      }
    }
    return true;
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = csvRecordsArray[i].split(',');
      if (curruntRecord.length == headerLength) {
        let csvRecord: any = {};
        //partnerclient
        csvRecord.role = curruntRecord[0] ? curruntRecord[0].trim() : '';
        let roleType = csvRecord.role.toLowerCase();
        if (this.role == 'staff' || this.role == 'partnerstaff' || this.role === 'partner') {
          //if (curruntRecord && curruntRecord.length == 11) {
          this.isPartnerStaffSheet = true;
          this.isClientSheet = false;
          csvRecord.designation = curruntRecord[1].trim();
          csvRecord.practiceUnitName = curruntRecord[2].trim();
          csvRecord.id = curruntRecord[3].trim();
          csvRecord.partnerFirstName = curruntRecord[4].trim();
          csvRecord.partnerLastName = curruntRecord[5].trim();
          csvRecord.partnerEmail = curruntRecord[6].trim().toLowerCase();
          csvRecord.licenseNo = curruntRecord[7].trim();
          csvRecord.officeLocation = curruntRecord[8].trim();
          csvRecord.partnerBusinessPhone = curruntRecord[9] ? curruntRecord[9].trim() : '';
          csvRecord.PartnerCellNo = curruntRecord[10] ? curruntRecord[10].trim() : '';

          csvRecord.legalEntityName = curruntRecord[11] ? curruntRecord[11].trim() : '';
          csvRecord.contactPersonName = curruntRecord[12] ? curruntRecord[12].trim() : '';
          csvRecord.clientEmail = curruntRecord[13] ? curruntRecord[13].trim().toLowerCase() : '';
          csvRecord.businessPhone = curruntRecord[14] ? curruntRecord[14].trim() : '';
          csvRecord.cellPhone = curruntRecord[15] ? curruntRecord[15].trim() : '';
          csvRecord.typeOfEntityName = curruntRecord[16] ? curruntRecord[16].trim() : '';
          csvRecord.incorporationDate = curruntRecord[17] ? curruntRecord[17].trim() : '';
          csvRecord.jurisdictionName = curruntRecord[18] ? curruntRecord[18].trim() : '';
          //}
        } else if (this.role == 'client' || this.role == 'partnerclient') {
          //if (curruntRecord && curruntRecord.length == 16) {
          if (this.loggedInUserData.origin_country_id == 38) {
            this.isClientSheet = true;
            this.isPartnerStaffSheet = false;
            //new version client
            csvRecord.id = curruntRecord[0] ? curruntRecord[0].trim() : '';
            csvRecord.legalEntityName = curruntRecord[1] ? curruntRecord[1].trim() : '';
            csvRecord.typeOfEntityName = curruntRecord[2] ? curruntRecord[2].trim() : '';
            csvRecord.incorporationDate = curruntRecord[3] ? curruntRecord[3].trim() : '';
            csvRecord.contactPersonName = curruntRecord[4] + ' ' + curruntRecord[5] ? curruntRecord[4].trim() + ' ' + curruntRecord[5].trim() : '';
            csvRecord.clientEmail = curruntRecord[6] ? curruntRecord[6].trim().toLowerCase() : '';
            csvRecord.businessPhone = curruntRecord[7] ? curruntRecord[7].trim() : '';
            csvRecord.cellPhone = curruntRecord[8] ? curruntRecord[8].trim() : '';
            csvRecord.jurisdictionName = curruntRecord[9] ? curruntRecord[9].trim() : '';
            csvRecord.address = curruntRecord[10] ? curruntRecord[10].trim() : '';//New
            csvRecord.city = curruntRecord[11] ? curruntRecord[11].trim() : '';//New
            csvRecord.state = curruntRecord[12] ? curruntRecord[12].trim() : '';//New
            csvRecord.country = curruntRecord[13] ? curruntRecord[13].trim() : '';//New
            csvRecord.postalCode = curruntRecord[14] ? curruntRecord[14].trim() : ''; //New
            csvRecord.engagementPartnerName = curruntRecord[15] ? curruntRecord[15].trim() : ''; //New
            csvRecord.engagementPartnerNameEmail = curruntRecord[16] ? curruntRecord[16].trim().toLowerCase() : ''; //New
            csvRecord.groupName = curruntRecord[17] ? curruntRecord[17].trim() : '';//New
            csvRecord.firstName = curruntRecord[4] ? curruntRecord[4].trim() : '';//New
            csvRecord.lastName = curruntRecord[5] ? curruntRecord[5].trim() : '';//New
          } else if (this.loggedInUserData.origin_country_id == 226) {
            this.isClientSheet = true;
            this.isPartnerStaffSheet = false;
            //new version client
            csvRecord.id = curruntRecord[0] ? curruntRecord[0].trim() : '';
            csvRecord.legalEntityName = curruntRecord[1] ? curruntRecord[1].trim() : '';
            csvRecord.typeOfEntityName = curruntRecord[2] ? curruntRecord[2].trim() : '';
            csvRecord.subentityname = curruntRecord[3] ? curruntRecord[3].trim() : '';
            csvRecord.incorporationDate = curruntRecord[4] ? curruntRecord[4].trim() : '';
            csvRecord.contactPersonName = curruntRecord[5] + ' ' + curruntRecord[6] ? curruntRecord[5].trim() + ' ' + curruntRecord[6].trim() : '';
            csvRecord.clientEmail = curruntRecord[7] ? curruntRecord[7].trim().toLowerCase() : '';
            csvRecord.businessPhone = curruntRecord[8] ? curruntRecord[8].trim() : '';
            csvRecord.cellPhone = curruntRecord[9] ? curruntRecord[9].trim() : '';
            csvRecord.address = curruntRecord[11] ? curruntRecord[11].trim() : '';//New
            csvRecord.city = curruntRecord[12] ? curruntRecord[12].trim() : '';//New
            csvRecord.jurisdictionName = curruntRecord[10] ? curruntRecord[10].trim() : '';
            csvRecord.state = curruntRecord[13] ? curruntRecord[13].trim() : '';//New
            csvRecord.country = curruntRecord[14] ? curruntRecord[14].trim() : '';//New
            csvRecord.postalCode = curruntRecord[15] ? curruntRecord[15].trim() : ''; //New
            csvRecord.engagementPartnerName = curruntRecord[16] ? curruntRecord[16].trim() : ''; //New
            csvRecord.engagementPartnerNameEmail = curruntRecord[17] ? curruntRecord[17].trim().toLowerCase() : ''; //New
            csvRecord.groupName = curruntRecord[18] ? curruntRecord[18].trim() : '';//New
            csvRecord.firstName = curruntRecord[5] ? curruntRecord[5].trim() : '';//New
            csvRecord.lastName = curruntRecord[6] ? curruntRecord[6].trim() : '';//New
          }
          //}
        }
        csvArr.push(csvRecord);
      }
    }
    try {
      if (this.csvReader) {
        this.csvReader.nativeElement.value = '';
      }
      if (this.csvReupload) {
        this.csvReupload.nativeElement.value = '';
      }

    } catch (e) {

    }
    return csvArr;
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith('.csv') && file.name.toLowerCase().replace(/[^.]/g, '').length == 1;
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileReset() {
    this.csvReader.nativeElement.value = '';
    this.records = [];
  }

  getAllPracticeUnits() {
    this.apiService.getPracticeUnits(this.useraccountId).subscribe(response => {
      this.practiceUnitsList = response;
    }, error => {

    });
  }

  // getting error on load
  getDeisgnationDetails() {
    this.apiService.getDesignation().subscribe(response => {
      if (response) {
        this.designationDetails = response;
      }
    }, error => {
    });
  }

  getJurisdiction() {
    this.apiService.getJurisdiction().subscribe(data => {
      this.jurisdictionDetails = data;
    });
  }

  getTypeOfEntity() {
    this.apiService.getTypeOfEntity().subscribe(data => {
      this.typeOfEntityDetails = data;
    });
  }

  saveData(e) {
    if (this.role == 'partner') {
      this.savePartners();
    } else if (this.role == 'client' || this.role == 'partnerclient') {
      this.saveClients();
    } else if (this.role == 'staff' || this.role == 'partnerstaff') {
      this.saveStaff();
    }
  }

  savePartners() {
    let partnerData = this.partnersList.map(partner => (
      {
        firstname: partner.partnerFirstName,
        lastname: partner.partnerLastName,
        practiceUnitName: partner.practiceUnitName,
        emailAddress: partner.partnerEmail,
        licenseNo: partner.licenseNo,
        officeLocation: partner.officeLocation,
        businessNo: partner.partnerBusinessPhone,
        phoneNo: partner.PartnerCellNo,
        clientList: null,
        partnerUserAcctId: 0,
        loginid: this.useraccountId,
        inid: partner.id
      }
    ));
    let partnersJsonObj = {'partnersList': partnerData};
    let partnersJsonString = JSON.stringify(partnersJsonObj);

    let data = {
      'partnerList': partnersJsonString
    };
    this.spinner.show();
    this.apiService.savePartnerJson(data).subscribe(response => {
      this.spinner.hide();
      this.toastr.success('Partner details saved successfully');
      this.router.navigate(['/dashboard/firm/partners']);
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });

  }

  gettypeofsubentity() {
    this.clientService.getTypeOfSubEntity().subscribe({
      next: res => {
        if (res) {
          var subtypeOfEntityDetails = JSON.parse(res);
          this.subtypeOfEntityDetails = subtypeOfEntityDetails.subentity;
        }
      }, error: e => {
        this.toastr.error(e.error);
        this.spinner.hide();
      }
    });
  }

  saveClients() {
    this.clientsList.forEach(client => {
      client.incorporationDate = this.datePipe.transform(client.incorporationDate, 'yyyy-MM-dd');
      this.jurisdictionDetails.forEach(item => {
        if (client.jurisdictionName === item.provincesname) {
          client.jurisdictionid = item.jurisdictionid;
        }
      });

      this.typeOfEntityDetails.forEach(item => {
        if (client.typeOfEntityName === item.typeofentityname) {
          client.typeofentityid = item.typeofentityid;
        }
      });

      this.subtypeOfEntityDetails.forEach(item => {
        if (client.subentityname === item.subentityname) {
          client.typeofsubentityid = item.typeofsubentityid;
        }
      });

      this.practiceUnitsList.forEach(item => {
        if (client.practiceUnitName === item.practiceUnitName) {
          client.practiceUnitId = item.practiceUnitId;
        }
      });

    });

    let clientData = this.clientsList.map(client => (
      {
        pbsname: client.legalEntityName,
        pcontactperson: client.contactPersonName,
        pbphonenumber: client.businessPhone,
        pcphonenumber: client.cellPhone,
        pemailadd: client.clientEmail,
        ppwd: 'adinovis',
        pjurisdictionid: client.jurisdictionid ? client.jurisdictionid : 1,
        ptypeofentityid: client.typeofentityid ? client.typeofentityid : 1,
        psubtypeofentityid: client.typeofsubentityid ? client.typeofsubentityid : 1,
        pincorporationdate: client.incorporationDate,
        practiceUnitId: client.practiceUnitId ? client.practiceUnitId : 0,
        ploginid: this.useraccountId,
        pid: client.id,
        address: client.address,
        city: client.city,
        postalCode: client.postalCode,
        groupName: client.groupName,
        pengagementPartnerName: client.engagementPartnerName,
        pengagementPartnerNameEmail: client.engagementPartnerNameEmail,
        country: client.country,
        firstName: client.contactPersonName.split(' ', 2)[0],
        lastName: client.contactPersonName.split(' ', 2)[1]
      }
    ));
    let data = { //1st
      'clientsList': clientData
    };
    this.spinner.show();
    this.clientService.saveClientJson(JSON.stringify(data)).subscribe({
      next: res => {
        this.spinner.hide();
        this.toastr.success('Clients details saved successfully');
        if (this.role == 'client') {
          this.router.navigate(['/dashboard/firm/clients']);
        } else {
          // this.router.navigate(['/dashboard/partner/partnerclients']);
          this.router.navigate(['/dashboard/members/clients']);
        }
        this.sharedService.sendNotifySubject.next({'uuid': this.loggedInUserData.useracctid, 'isfirm': 1});
      }, error: e => {
        this.spinner.hide();
        this.toastr.error(e.error);
      }
    });
  }

  saveStaff() {
    let staffJsonString = '';
    if (this.staffList && this.staffList.length > 0) {
      this.staffList.forEach(staff => {
        this.designationDetails.forEach(item => {
          if (staff.designation == item.designationName) {
            staff.designationId = item.designationId;
          }
        });
      });
      let staffData = this.staffList.map(staff => (
        {
          id: staff.id,
          firstName: staff.partnerFirstName,
          lastName: staff.partnerLastName,
          practiceUnitName: staff.practiceUnitName,
          emailAddress: staff.partnerEmail,
          officeLocation: staff.officeLocation,
          businessNo: staff.partnerBusinessPhone,
          phoneNo: staff.PartnerCellNo,
          designationId: staff.designationId ? staff.designationId : 0,
          designationName: staff.designation,
          firmStaffId: 0,
          staffuseraccountid: 0,
          firmPartnerId: 0,
          loginId: this.useraccountId,
          licenseNo: staff.licenseNo
        }
      ));
      let staffJsonObj = {'staffList': staffData};
      staffJsonString = JSON.stringify(staffJsonObj);
    }

    let data = {
      'staffList': staffJsonString
    };
    this.spinner.show();
    this.apiService.saveStaffJson(data).subscribe(response => {
      this.spinner.hide();
      this.toastr.success('Staff details saved successfully');
      if (this.role === 'staff') {
        this.router.navigate(['/dashboard/firm/firmstaff']);
      } else {
        this.router.navigate(['/dashboard/partner/partnerstaff']);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });

  }

  editCsv() {
    const dialogRef = this.dialog.open(this.howtoEdit, {panelClass: 'tb-edit-csv'});
    dialogRef.afterClosed().subscribe(result => { });
  }

  //Dialogbox for Duplicate and License number
  openLicenceDialog(): void {
    const dialogRef = this.dialog.open(this.importCsvDialog, {
      width: '450px'

    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  ngOnDestroy(): void {
    this.subscriptions && this.subscriptions.unsubscribe();
  }

  downloadTemplate() {
    const fileName = this.targetTemplateName();
    console.log(fileName);
    this.apiService.templateDownload(this.templateUrl).subscribe((blob) => {
      saveAs(blob, fileName);
    });
  }
}
