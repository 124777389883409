import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SurveyUtils } from './helpers/survey-util';
import { ExportPlatformService } from './services/export-platform.service';
import { NPSSurveyService } from './services/survey.service';
import { WebSocketService } from './services/web-socket.service';

declare global {
  interface Window {
    AdobeDC: any;
    google: any;
    grecaptcha: any;
    watsonAssistantChatOptions: any;
    webChatInstance: any;
    webkitSpeechRecognition: any;
    SpeechRecognition: any;
    Plaid: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent {

  @ViewChild('exportFailed') exportFailed: TemplateRef<any>;
  isModalVisible: boolean = false;

  constructor(public dialog: MatDialog, private exportPlatformService: ExportPlatformService, private npsService: NPSSurveyService) {
    this.npsService.modalVisibility.subscribe(isVisible => {
      if (!isVisible) {
        this.isModalVisible = isVisible;
      } else {
        this.isModalVisible = !SurveyUtils.isPathBlacklisted(window.location.pathname);
      }
    });
    this.exportPlatformService.exportFailed$.pipe(takeUntilDestroyed()).subscribe(res => {
      if (res) {
        this.dialog.open(this.exportFailed);
      }
    });
  }

  retryExport() {
    this.exportPlatformService.retryExportData();
  }
}

