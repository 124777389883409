// $primary: #337EBD;
$primary: #004B92;
$primary-light: #1C63A6;
$primary-lighter: #F2F9FF;

$primary-medium: #D8ECFE;
$primary-darker: #074075;

$primary-1: #E1EEFA;

$primary-2: #7FB9F0;
$primary-3: #5599D8;
$primary-2-hover: $primary;


$primary-4: #2A79C3;
$primary-5: $primary-light;
$primary-6: #004B92;
$primary-7: #004B92;


$blue-light: #099FF1;
$blue: #192E4A;
$blue-dark: #152841;




$secondary: $primary-1;
$secondary-light: $primary-lighter;
$secondary-lighter: #EEEFFE;
$secondary-dark: #AAD3FA;
$secondary-hover: $secondary-dark;
$secondary-disabled: $primary-lighter;

$search-result: $primary-medium;

//2facodes//
$secondary-color: #0F0F0F;
$secondary-2: #424242;
$secondary-1: #E4E4E4;


$cyan: #088AB2;

$tin: #A7C4CB;
$tin-hover: #6b888f;

$white: #ffffff;
$white-6: #F8F8F8;

//Black
$black: #000000;
$black-6: #0F0F0F;
$black-8: #101828;
$dark: $black;

//Gray Light
$gray-lighter: #f1f1f3;
$gray-lighter-1: #F4F7FB;
$gray-50: #F8F8FA;
$gray: #BAB9BD;
$gray-1: #F1F1F3;
$gray-2: #E2E5EB;
$gray-3: #C3CBD6;
$gray-4: #A7B2C2;
$gray-5: #778599;
$gray-6: #607087;
$gray-7: #475467;
$gray-8: #344054;
$gray-9: #000000;
$gray-10: #101D28;

$lighter-gray: #DEDEDE;
$light-gray-1: $gray-1;
$light-gray-2: #F0F0F0;
$light-gray-3: #D0D5DD;
$light-gray-4: $gray-2;
$base-color: $gray-9;
//Gray Dark
$dark-gray-2: #424242;
$gray-dark-3: #6D6D6D;


//Tussock
$tussock: #C2764B;
$tussock-1: #F4EDE9;

//Purple
$purple: #7856C8;
$purple-1: #E9E6F1;
$purple-5: #7A5AF8;

//Yellow
$yellow: #FFB800;
$yellow-light: #FEDF89;
$yellow-lighter: #F8EFD5;
$yellow-1: #F6EFDD;
$dark-yellow:#C2A24B;

//Blue
$blue-astro: #5599D8;
$blue-astro-1: #E5ECF3;
$blue-astro-5: #0BA5EC;
$blue-moody: #717FC9;
$blue-moody-1: #E8EAF1;
$tertiary-blue-7: #5C7880;
$tertiary-blue-lighter: #EDF6F7;
$seafoam-blue:#4BB2C2;

//success
$success-50: #EDFCF2;
$success-lighter: #F6FEF9;
$success-light: #6CE9A6;
$success: #37A77C;
$success-new: #12B76A;
$success-dark: #087443;
$success-darker: #027A48;
$success-1: #D1FADF;
$success-50:#ECFDF3;
$light-success:#E3FBCC;
$light-blue:#ddedf8;
//Pink
$pink: #F670C7;
$pink-dark: #DD2590;
$pink-darker: #C11574;
$pink-light: #FCCEEE;
$pink-lighter: #FEF6FB;


//Warninng
$warning: #F79009;
$warning-lighter: #FFFCF5;
$warning-light: #FEC84B;
$warning-1: #FFFAEB;
$warning-dark: #DC6803;
$warning-darker: #B54708;
$warning-100: #FEF0C7;

//error
$error: #D92D20;
$error-light: #FDA29B;
$error-lighter: #FFFBFA;
$error-darker: #B42318;
$error-50: #FEF3F2;
$error-1: #FEE4E2;
$error-2: #FECDCA;
$error-3: $error-light;
$error-8: #912018;
$error-hover: $error-darker;
// $error-hover: #941c14;

$indigo-50: #EEF4FF;

// QuickBooks
$qb-primary:#3376bf;
$qb-hover-bg: #e1e5ea;

//upgrade plan
$up-red:#F04438;

//Sizes
$size-1: 0.0625rem;
$size-2: 0.125rem;
$size-3: 0.1875rem;
$size-4: 0.25rem;
$size-6: 0.375rem;
$size-8: 0.5rem;
$size-10: 0.625rem;
$size-12: 0.75rem;
$size-13: 0.8125rem;
$size-14: 0.875rem;
$size-15: 0.9375rem;
$size-16: 1rem;
$size-18: 1.125rem;
$size-20: 1.25rem;
$size-22: 1.375rem;
$size-24: 1.5rem;
$size-26: 1.625rem;
$size-28: 1.75rem;
$size-30: 1.875rem;
$size-32: 2rem;
$size-34: 2.125rem;
$size-35: 2.188rem;
$size-36: 2.25rem;
$size-38: 2.375rem;
$size-40: 2.5rem;
$size-42: 2.625rem;
$size-44: 2.75rem;
$size-46: 2.875rem;
$size-48: 3rem;
$size-50: 3.125rem;
$size-58: 3.625rem;
$size-60: 3.75rem;


//Border-radius
$radius-4: $size-4;
$radius-6: $size-6;
$radius-8: $size-8;
$radius-12: $size-12;
$radius-16: $size-16;
$radius-28:$size-28;




//2facodes//
$primarylatest: #004B92;
$tabsactive: #E1EEFA;
$tabsbrdactive: #004B92;
$tabsbg:  #F8F8FA;
$frame: #F1F1F3;
$inpbrd: #C3CBD6;
$boxshadow: 0px 1px 2px rgba(16, 24, 40, 0.05);


//Spacing
$p-20: $size-20;
$p-32: $size-32;
$m-10: $size-10;
$m-20: $size-20;
$m-32: $size-32;



//Global Variables
$collapsed-nav: 69px;
$open-nav: 250px;
$nav-height: $size-50;
$normal-button-height: $size-44;
$medium-button-height: $size-40;
$small-button-height: $size-30;
$smaller-button-height: $size-24;
