.bg-primary-lighter {
    background-color: $primary-lighter !important;
}
.bg-secondary {
  background-color: $secondary !important;
}

.bg-secondary-lighter {
  background-color: $secondary-lighter !important;
}

.bg-primary {
    background-color: $primary !important;
}

.bg-blue {
  background-color: $blue !important;
}

.bg-blue-dark {
  background-color: $blue-dark !important;
}

.bg-cyan {
  background: $cyan!important;
}

.bg-gray-50 {
    background-color: $gray-50 !important;
}
.bg-gray-1 {
    background-color: $gray-1 !important;
}
.bg-gray-2 {
    background-color: $gray-2 !important;
}
.bg-gray-3 {
    background-color: $gray-3 !important;
}
.bg-success {
    background-color: $success !important;
}
.bg-success-1 {
    background-color: $success-1 !important;
}
.bg-success-lighter{
    background-color: $success-lighter !important;
}
.bg-success-new {
    background-color: $success-new !important;
}
.bg-error {
    background-color: $error !important;
}
.bg-error-1 {
    background-color: $error-1 !important;
}
.bg-error-50 {
    background-color: $error-50 !important;
}

.bg-tussock {
    background-color: $tussock !important;
}

.bg-purple {
    background-color: $purple !important;
}
.bg-warning-lighter{
    background-color: $warning-lighter !important;
}
.bg-light-success{
    background-color: $light-success !important;
}
.bg-light-blue{
    background-color: $light-blue !important;
}
.bg-seafoam-blue{
    background-color: $seafoam-blue !important;
}
.bg-dark-yellow{
    background-color: $dark-yellow !important;
}

.border-primary {
    border-color: $primary !important;
    &-darker{
        border-color: $primary-darker !important;
    }
}

//Border Radius Classes
.br-0 {
    border-radius: 0;
}
.br-4 {
    border-radius: $radius-4;
}
.br-6 {
    border-radius: $radius-6;
}
.br-8 {
    border-radius: $radius-8;
}
.br-12 {
    border-radius: $radius-12;
}
.br-16 {
    border-radius: $radius-16;
}
.br-28{
    border-radius:$radius-28;
}

[class^="border-"] {
    &[class*="primary"] {
        border: $size-1 solid $primary;
    }
    &[class*="gray-2"] {
        border: $size-1 solid $gray-2;
    }
    &[class*="gray-3"] {
        border: $size-1 solid $gray-3;
    }
    &[class*="top"] {
        border-width: $size-1 0 0 0;
    }
    &[class*="right"] {
        border-width: 0 $size-1 0 0;
    }
    &[class*="bottom"] {
        border-width: 0 0 $size-1 0;
    }
    &[class*="left"] {
        border-width: 0 0 0 $size-1;
    }
}
