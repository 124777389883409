@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700,700i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');

// $font-primary: 'Open Sans', sans-serif;
$font-primary: 'Inter', sans-serif !important;

$font-inter: 'Inter', sans-serif !important;

$font-arial: 'Arial', sans-serif !important;

$font-roboto: "Roboto", sans-serif;

$font-poppins: "Poppins", sans-serif !important;

$font-sign: 'Alex Brush', cursive !important;

body {
    .material-icons {
        font-family: 'Material Icons' !important;
    }
}

//Font Family
.font-inter {
    font-family: $font-inter;
}
.font-primary {
    font-family: $font-primary;
}
.font-arial {
  font-family: $font-arial;
}
.font-poppins {
  font-family: $font-poppins;
}
.font-roboto {
  font-family: $font-roboto;
}

.font-sign {
    font-family: $font-sign;
}
//Font-weight
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;


html {
    font-size: 16px !important;
}

body {
    font-size: $size-14;
    line-height: 1.43;
    font-family: $font-primary;
    font-weight: $regular;
    color: $base-color;
}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
    font-family: $font-primary;
    font-weight: $semibold;
    color: $gray-9;
    line-height: 1.43;
    margin: 0;

    &.label {
        color: $gray-7;
        font-weight: $medium;
    }
}
h2,.h2 {
    font-size: $size-30;
    @media (max-width: 1366px) {
        font-size: $size-26;
    }
    @media (max-width: 992px) {
        font-size: $size-22;
    }
    @media (max-width: 768px) {
        font-size: $size-20;
    }
}
h3,.h3 {
    font-size: $size-24;
    @media (max-width: 991px) {
        font-size: $size-20;
    }
}
h4,.h4 {
    font-size: $size-18;
    @media (max-width: 991px) {
        font-size: $size-16;
    }
}
h5,.h5 {
    font-size: $size-16;
}
h6,.h6 {
    font-size: $size-14;
}
.title {
    &:empty {
        display: none;
    }
}
p, pre {
    font-family: $font-primary;
    margin: 0;
    font-weight: $regular;
    color: $gray-9;
    font-size: $size-14;
    line-height: 1.43;
}
.text-link {
    color: $primary !important;
    font-weight: $semibold !important;
    border: none;
    background-color: transparent;
    padding: 0 !important;
    min-width: auto;
    font-size: $size-14;
    line-height: 1.3;
    cursor: pointer;
    text-decoration: none;
    [class^="fa-"] {
      font-size: $size-18;
      color: $primary;
    }

    &:hover,
    &:focus {
        color: $primary-darker !important;
        text-decoration: none;

        [class^="fa-"] {
          color: $primary-darker;
        }
    }
    &.disabled,
    &:disabled,
    &[disabled="true"] {
      color: $gray-7 !important;
      cursor: initial;
    }
}

.text-ellipse,
.ellipse {
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
}
.text-italic {
    font-style: italic !important;
}
.text-preline {
    white-space: pre-line !important;
}

.text-block {
    @include flex-combinator(column, nowrap);
    width: 100%;
}
.text-left,
.text-start {
    justify-content: flex-start !important;
    text-align: left !important;
}
.text-right,
.text-end {
    justify-content: flex-end !important;
    text-align: right !important;
}
.ws-normal,
.white-space-normal {
    white-space: normal !important;
}


.sub-title {
    font-weight: $medium;
}




//Font-sizes
.fs-0 {
    font-size: 0 !important;
}
.fs-10 {
    font-size: $size-10 !important;
}
.fs-12 {
    font-size: $size-12 !important;
}
.fs-13 {
    font-size: $size-13 !important;
}
.fs-14 {
    font-size: $size-14 !important;
}
.fs-15{
    font-size: $size-15 !important;
}
.fs-16 {
    font-size: $size-16 !important;
}
.fs-18 {
    font-size: $size-18 !important;
}
.fs-20{
    font-size: $size-20 !important;
}
.fs-24{
    font-size: $size-24 !important;
}
.fs-48{
    font-size: $size-48 !important;
}
.fs-2 {
    font-size: 0.5rem !important;
}
.fs-3 {
    font-size: 1rem !important;
}
.fs-4 {
    font-size: 1.5rem !important;
}
.fs-5 {
    font-size: 2rem !important;
}

.fs-50 {
  font-size: 3.125rem !important;
}
.fs-36{
    font-size: $size-36 !important;
}

//Font-weight
.fw-regular {
    font-weight: $regular !important;
}
.fw-medium {
    font-weight: $medium !important;
}
.fw-semibold {
    font-weight: $semibold !important;
}
.fw-bold {
    font-weight: $bold !important;
}
.fw-extrabold {
    font-weight: $extrabold !important;
}



.text-primary {
    color: $primary !important;
}
.text-primary-4 {
    color: $primary-4 !important;
}

.text-primary-darker {
  color: $primary-darker !important;
}

.text-success {
    color: $success !important;
}
.text-success-darker {
    color: $success-darker !important;
}

.text-tin {
    color: $tin !important;
}

.text-purple {
    color: $purple !important;
}

.text-warning {
    color: $warning !important;
}
.text-warning-darker {
    color: $warning-darker !important;
}
.text-warning-dark{
    color: $warning-dark !important;
}
.text-yellow {
  color: $yellow !important;
}

.text-error {
    color: $error !important;
    path {
        stroke: $error !important;
    }
}

.text-error-darker {
    color: $error-darker !important;
}

.text-gray {
    color: $gray !important;
}

.text-gray-3 {
    color: $light-gray-3 !important;
}
.text-gray-5 {
    color: $gray-5 !important;
}
.text-gray-6 {
    color: $gray-6 !important;
}
.text-gray-7 {
    color: $gray-7 !important;
}
.text-gray-8 {
    color: $gray-8 !important;
}
.text-gray-9 {
    color: $gray-9 !important;
}
.text-gray-10{
    color: $gray-10 !important;
}

.text-purple-5 {
    color: $purple-5 !important;
}
.text-primary-darker{
color:$primary-darker !important;
}
.search-highlight,
.searchActive {
    // background-color: $yellow-lighter !important;
    background-color: $search-result !important;
}

.text-black-8{
    color: $black-8 !important;
}

.text-blue-light{
    color: #099FF1;
}
